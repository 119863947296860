import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Medusa from "@medusajs/medusa-js";
import { AppState } from "./store";
import { endpoints } from "./apiSlice";
import { Cart, Region } from "@medusajs/medusa";
import { rejectOrThrowError } from "../utils/display-errors";
import { LocationResult } from "../models/models";

const medusa = new Medusa({
  baseUrl: process.env.NEXT_PUBLIC_MEDUSA_BASE_URL as string,
  maxRetries: 3,
});

export const addLineItemToCart = createAsyncThunk(
  "medusa/carts/add-line-item",
  async (
    payload: {
      variant_id: string;
      quantity: number;
      cartId: string;
      pay_what_you_can_price?: number;
    },
    thunkAPI,
  ) => {
    try {
      const response = await medusa.carts.lineItems.create(payload.cartId, {
        variant_id: payload.variant_id,
        quantity: payload.quantity,
      });
      if (payload.pay_what_you_can_price) {
        const line_item = response.cart.items.find(
          (i) => i.variant_id === payload.variant_id,
        );
        if (!line_item) {
          return rejectOrThrowError(
            new Error("Could not find line item"),
            thunkAPI,
          );
        }
        await thunkAPI.dispatch(
          endpoints.updatePriceForPayWhatYouLikeLineItem.initiate({
            line_item_id: line_item.id,
            price: payload.pay_what_you_can_price,
          }),
        );
      }
      return addShippingOption(response.cart.id);
    } catch (e: any) {
      return rejectOrThrowError(e, thunkAPI);
    }
  },
);

export async function addShippingOption(cartId: string) {
  const shippingOptions = (await medusa.shippingOptions.listCartOptions(cartId))
    .shipping_options;
  const serviceFeesOption = shippingOptions.find(
    (so) => so.provider_id === "service-fees",
  );
  if (serviceFeesOption) {
    const response = await medusa.carts.addShippingMethod(cartId, {
      option_id: serviceFeesOption.id as string,
    });
    return response.cart;
  } else {
    const cart_response = await medusa.carts.retrieve(cartId);
    return cart_response.cart;
  }
}

export const updateLineItemQuantity = createAsyncThunk(
  "medusa/carts/update-line-item-quantity",
  async (
    update: {
      lineItemId: string;
      cartId: string;
      quantity: number;
      pay_what_you_can_price?: number;
    },
    thunkAPI,
  ) => {
    try {
      const response = await medusa.carts.lineItems.update(
        update.cartId,
        update.lineItemId,
        { quantity: update.quantity },
      );
      if (update.pay_what_you_can_price) {
        await thunkAPI.dispatch(
          endpoints.updatePriceForPayWhatYouLikeLineItem.initiate({
            line_item_id: update.lineItemId,
            price: update.pay_what_you_can_price,
          }),
        );
      }
      return addShippingOption(response.cart.id);
    } catch (e: any) {
      return rejectOrThrowError(e, thunkAPI);
    }
  },
);

export const deleteLineItemFromCart = createAsyncThunk(
  "medusa/carts/delete-line-item",
  async (
    payload: {
      lineItemId: string;
      cartId: string;
    },
    thunkAPI,
  ) => {
    const response = await medusa.carts.lineItems.delete(
      payload.cartId,
      payload.lineItemId,
    );
    return addShippingOption(response.cart.id);
  },
);

export const updateCustomerIdInCart = createAsyncThunk(
  "medusa/carts/update-customer-id",
  async (
    payload: {
      customerId: string;
      cartId: string;
    },
    thunkAPI,
  ) => {
    const response = await medusa.carts.update(payload.cartId, {
      customer_id: payload.customerId,
    });
    return response.cart;
  },
);

export const addPromoCode = createAsyncThunk(
  "medusa/carts/add-promo-code",
  async (
    payload: {
      discountCode: string;
      cartId: string;
    },
    thunkAPI,
  ) => {
    try {
      const response = await medusa.carts.update(payload.cartId, {
        discounts: [{ code: payload.discountCode }],
      });
      const cart = await addShippingOption(response.cart.id);
      await thunkAPI.dispatch(replaceCart(cart));
      return cart;
    } catch (e) {
      return rejectOrThrowError(e, thunkAPI);
    }
  },
);

export const addGiftCardCode = createAsyncThunk(
  "medusa/carts/add-gift-card-code",
  async (
    payload: {
      giftCardCode: string;
      cartId: string;
    },
    thunkAPI,
  ) => {
    const c = await medusa.carts.retrieve(payload.cartId);
    const gift_cards = c.cart.gift_cards.map((gc) => ({ code: gc.code }));
    gift_cards.push({ code: payload.giftCardCode });
    await medusa.carts.update(payload.cartId, {
      gift_cards,
    });
    const cart = await addShippingOption(payload.cartId);
    await thunkAPI.dispatch(replaceCart(cart));
    return cart;
  },
);

export const removePromoCode = createAsyncThunk(
  "medusa/carts/remove-promo-code",
  async (
    payload: {
      discountCode: string;
      cartId: string;
    },
    thunkAPI,
  ) => {
    const response = await medusa.carts.deleteDiscount(
      payload.cartId,
      payload.discountCode,
    );
    const cart = await addShippingOption(response.cart.id);
    await thunkAPI.dispatch(replaceCart(cart));
    return cart;
  },
);

export const removeGiftCardCode = createAsyncThunk(
  "medusa/carts/remove-gift-card-code",
  async (cartId: string, thunkAPI) => {
    const response = await medusa.carts.update(cartId, { gift_cards: [] });
    const cart = await addShippingOption(response.cart.id);
    await thunkAPI.dispatch(replaceCart(cart));
    return cart;
  },
);

export const completeCart = createAsyncThunk(
  "medusa/carts/complete",
  async (cartId: string, thunkAPI): Promise<void> => {
    try {
      const { type, data } = await medusa.carts.complete(cartId);
      if (type !== "order") {
        thunkAPI.dispatch(setCartCompleteError(data));
        thunkAPI.dispatch(newCart());
      }
    } catch (e) {
      thunkAPI.dispatch(setCartCompleteError(e));
      thunkAPI.dispatch(newCart());
    }
  },
);

export const newCart = createAsyncThunk(
  "medusa/carts/new-cart",
  async (thunkAPI): Promise<Cart | undefined> => {
    localStorage.removeItem("cartId");
    return await createOrFetchCart(thunkAPI);
  },
);

export const fetchCart = createAsyncThunk(
  "medusa/carts/fetch",
  async (arg, thunkAPI): Promise<Cart | undefined> => {
    const cart = await createOrFetchCart(thunkAPI);
    thunkAPI.dispatch(replaceCart(cart));
    return cart;
  },
);

export const updateCartRegion = createAsyncThunk(
  "medusa/carts/update-region",
  async (regionId: string, thunkAPI): Promise<Cart | undefined> => {
    const cartId = localStorage.getItem("cartId") as string;
    if (!cartId) return await createOrFetchCart(thunkAPI);
    const response = await medusa.carts.update(cartId, { region_id: regionId });
    const cart = response.cart as Cart;
    let result;
    for (const item of cart.items) {
      result = await medusa.carts.lineItems.delete(cartId, item.id);
    }
    return result ? (result.cart as Cart) : cart;
  },
);

const createOrFetchCart = async (thunkAPI: any): Promise<Cart | undefined> => {
  const cartId = localStorage.getItem("cartId");
  if (!cartId) {
    const result = createRegionalCart(thunkAPI);
    if (result !== undefined) return result;
  } else {
    try {
      const response = await medusa.carts.retrieve(cartId);
      thunkAPI.dispatch(assignRegion(response.cart.region));
      return response.cart as Cart;
    } catch (e) {
      try {
        const result = createRegionalCart(thunkAPI);
        if (result !== undefined) return result;
      } catch (e) {
        throw e;
      }
    }
  }
};

function getRegionBasedOnLocation(
  regionList: Region[],
  locationResult: LocationResult,
): Region | undefined {
  const region = regionList.find((r) =>
    r.countries.some(
      (c) => c.iso_2.toUpperCase() === locationResult.countryCode,
    ),
  );
  if (region) return region;
  else if (regionList.length) return regionList[0];
}

const createRegionalCart = async (thunkAPI: any): Promise<Cart | undefined> => {
  let locationData: LocationResult | null = null;
  let locationDataString = localStorage.getItem("location");
  if (locationDataString) {
    locationData = JSON.parse(locationDataString);
  }
  const regionString = localStorage.getItem("region");
  let region: Region | undefined;
  if (regionString) {
    region = JSON.parse(regionString);
  } else if (!regionString && locationData) {
    const response = await medusa.regions.list();
    region = getRegionBasedOnLocation(response.regions, locationData);
  }
  if (region) {
    thunkAPI.dispatch(assignRegion(region));
  } else {
    thunkAPI.dispatch(regionNotAssigned());
    return undefined;
  }
  const createCartResponse = await medusa.carts.create({
    region_id: region.id,
    context: { locationInfo: locationData },
  });
  localStorage.setItem("cartId", createCartResponse.cart.id);
  return createCartResponse.cart as Cart;
};

export enum RegionStatus {
  NOT_INITIALIZED,
  ASSIGNED,
  INITIALIZED_NOT_ASSIGNED,
}

export interface CartState {
  cart: Partial<Cart> | undefined;
  cartCompleteError: any;
  regionStatus: RegionStatus;
  stripeSecretKey: string | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined | { error: { message: string } };
}

const initialState: CartState = {
  cart: { items: [], total: 0, subtotal: 0 },
  cartCompleteError: undefined,
  regionStatus: RegionStatus.NOT_INITIALIZED,
  stripeSecretKey: undefined,
  status: "idle",
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    replaceCart(state, action) {
      state.cart = action.payload;
    },
    regionNotAssigned(state) {
      state.regionStatus = RegionStatus.INITIALIZED_NOT_ASSIGNED;
    },
    // we only need this for legacy cases where regionId is not set but cartId was. This is so it can be called during
    // cart retrieve to set the regionId in local storage - otherwise, this could just have been part of updateCartRegion)
    // We are storing regionId to avoid making an API call
    assignRegion(state, action) {
      state.regionStatus = RegionStatus.ASSIGNED;
      localStorage.setItem("regionId", action.payload.id);
      // used only in load-stripe.ts to figure out which provider to load
      localStorage.setItem("region", JSON.stringify(action.payload));
    },
    setCartCompleteError(state, action) {
      state.cartCompleteError = action.payload;
    },
    clearCartCompleteError(state) {
      state.cartCompleteError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        // @ts-ignore TODO
        state.cart = action.payload;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLineItemToCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addLineItemToCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cart = action.payload;
      })
      .addCase(
        addLineItemToCart.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload;
        },
      )
      .addCase(completeCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(completeCart.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(completeCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(newCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(newCart.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(newCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteLineItemFromCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteLineItemFromCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.cart = action.payload;
      })
      .addCase(deleteLineItemFromCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateLineItemQuantity.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateLineItemQuantity.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.cart = action.payload;
      })
      .addCase(
        updateLineItemQuantity.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload;
        },
      )
      .addCase(updateCustomerIdInCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCustomerIdInCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.cart = action.payload;
      })
      .addCase(updateCustomerIdInCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addPromoCode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addPromoCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.cart = action.payload;
      })
      .addCase(addPromoCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removePromoCode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removePromoCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.cart = action.payload;
      })
      .addCase(removePromoCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCartRegion.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCartRegion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cart = action.payload;
      })
      .addCase(updateCartRegion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectCartState = (state: AppState): Partial<Cart | undefined> =>
  state.cart.cart;
export const selectCartId = (state: AppState): string | undefined =>
  state.cart.cart?.id;
export const selectRegionStatus = (state: AppState): RegionStatus =>
  state.cart.regionStatus;
export const selectCartCompleteError = (state: AppState): any =>
  state.cart.cartCompleteError;

export default cartSlice.reducer;

export const {
  replaceCart,
  regionNotAssigned,
  assignRegion,
  clearCartCompleteError,
  setCartCompleteError,
} = cartSlice.actions;
