import { CalendarEvent, Product } from "../models/models";
import dayjs from "dayjs";
import { SHOWTIME_FORMAT_STORED } from "./date";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
export function convertProductsToCalendarEvents(
  products: Product[],
  hidePast?: boolean,
): CalendarEvent[] {
  // @ts-ignore
  const twoHoursAgo = dayjs.utc().subtract(2, "h").toDate();
  let result = products.flatMap((p) =>
    p.variants.map((v) => {
      // @ts-ignore
      return {
        // @ts-ignore
        end: dayjs.utc(v.startsAtUtc).add(2, "h").toDate(),
        title: p.title,
        timezone: p.venueArrangement.venue.timezone,
        start: v.startsAtUtc,
        startTime: dayjs(v.startsAt, SHOWTIME_FORMAT_STORED).format("h:mm A"),
        productId: p.id,
        thumbnail: v.thumbnail ? v.thumbnail : (p.thumbnail as string),
        handle: p.handle as string,
        startsAt: v.startsAt,
      };
    }),
  );
  if (hidePast) {
    // @ts-ignore
    result = result.filter((r) =>
      // @ts-ignore
      dayjs.utc(r.start).isSameOrAfter(twoHoursAgo),
    );
  }
  return result;
}
