import { ApiError } from "@models/models";
import toast from "react-hot-toast";

export function toastWarning(apiError: ApiError) {
  toast.error(apiError.error[0]);
}

const isMedusaJsErrorResponse = (e: any): boolean => {
  return e.response && e.response.data && e.response.data.type;
};

const medusaErrorCodeToMessageMapping: { [key: string]: string } = {
  insufficient_inventory: "Not enough stock available.",
};

export const rejectOrThrowError = (e: any, thunkAPI: any) => {
  if (isMedusaJsErrorResponse(e)) {
    const message = medusaErrorCodeToMessageMapping[e.response.data.code];
    if (message) {
      return thunkAPI.rejectWithValue({
        error: [message],
      });
    } else if (e.response.data.message) {
      return thunkAPI.rejectWithValue({
        error: [e.response.data.message],
      });
    }
  } else if (e.response.data.error?.length) {
    return thunkAPI.rejectWithValue({
      error: [e.response.data.error[0]],
    });
  }
  throw e;
};

export const throwIfError = (result: any) => {
  if (result.payload.error?.length) {
    throw new Error(result.payload.error[0]);
  } else {
    return result.payload;
  }
};
