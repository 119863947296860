import { useEffect } from "react";
import useMailingList from "@hooks/mailing-list";

export default function SelectMailingListId({
  onSelectMailingList,
}: {
  onSelectMailingList: (value: string) => void;
}) {
  const { mailing_list } = useMailingList();

  useEffect(() => {
    if (mailing_list) {
      onSelectMailingList(mailing_list.id);
    }
  }, [mailing_list]);

  return <div></div>;
}
