export type CampaignCreationStep = {
  id: string;
  description: string;
  stepNumber: number;
};

export type MetaCity = {
  key: string;
  name: string;
  country_code: string;
  region: string;
};

export type MetaCountry = {
  country_code: string;
  name: string;
};

export type MetaInterest = {
  id: string;
  name: string;
};

export const UTC_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZZ";

export const campaignCreationSteps: CampaignCreationStep[] = [
  {
    id: "select-name-and-type",
    description: "Campaign Name and Type",
    stepNumber: 1,
  },
  {
    id: "select-locations",
    description: "Select Locations",
    stepNumber: 2,
  },
  {
    id: "select-custom-audience",
    description: "Select Custom Audience",
    stepNumber: 3,
  },
  {
    id: "select-ad-creatives",
    description: "Select Graphics and Copy",
    stepNumber: 4,
  },
];

export type MetaPlatform = {
  id: string;
  name: string;
};

export const metaPlatforms: MetaPlatform[] = [
  {
    id: "facebook",
    name: "Facebook",
  },
  {
    id: "instagram",
    name: "Instagram",
  },
];
