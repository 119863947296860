import { useSelector } from "react-redux";
import { selectCustomer } from "@store/user-slice";
import { useEffect, useState } from "react";
import { selectCartId, updateCustomerIdInCart } from "@store/cart-slice";
import { useAppDispatch } from "@store/store";
import { Customer } from "@models/models";
import { sendGaEvent } from "@utils/google-analytics";
import { usePathname, useRouter } from "next/navigation";

export function useStoreUser() {
  const pathname = usePathname();
  const router = useRouter();
  const customer = useSelector(selectCustomer);
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState<string>();
  const [picture, setPicture] = useState<string>();
  const cartId = useSelector(selectCartId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (customer === undefined && pathname?.startsWith("/store/account")) {
      router.push("/");
    }
    if (customer === undefined || cartId === undefined) return;
    setLoggedIn(true);
    if (customer) {
      sendGaEvent("login", {});
      setEmail(customer.email);
      setPictureVar(customer);
      dispatch(updateCustomerIdInCart({ customerId: customer.id, cartId }));
    }
  }, [customer?.id, cartId]);

  const setPictureVar = (c: Customer) => {
    let picture =
      "https://images.unsplash.com/photo-1586374579358-9d19d632b6df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80";
    const profile = c?.metadata?.profile as any;
    if (profile && profile.picture) {
      picture = profile.picture;
    }
    setPicture(picture);
  };

  return { loggedIn, customer, email, picture };
}
