"use client";
import { Form, Formik } from "formik";
import { TextInput } from "./forms/text-input";
import Button from "./forms/button";
import { useAddSubscriberToMailingListMutation } from "../store/adsSlice";
import { useState } from "react";
import { Message } from "./message";
import { useErrorHandler } from "@hooks/error-handler";

export type MailingListSubscriberValues = {
  name: string;
  email: string;
  mailing_list_id: string;
};

type MailingListSignUpProps = {
  mailingListId: string;
  mailingListName?: string;
};

export default function MailingListSignUp({
  mailingListId,
  mailingListName,
}: MailingListSignUpProps) {
  const [subscribe] = useAddSubscriberToMailingListMutation();
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = useErrorHandler(
    async (values: MailingListSubscriberValues) => {
      await subscribe(values).unwrap();
      setSubscribed(true);
    },
  );
  return (
    <div
      className={
        "flex w-full sm:w-fit p-4  rounded-xl bg-gray-100 items-center justify-center"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
          email: "",
          mailing_list_id: mailingListId,
        }}
        onSubmit={handleSubscribe}
      >
        {subscribed ? (
          <Message
            message={"Thank you for subscribing!"}
            type={"success"}
            title={"Subscribed successfully"}
          />
        ) : (
          <Form className={"flex flex-col gap-y-4"}>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              {mailingListName && (
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Join {mailingListName}
                </h2>
              )}
            </div>

            <TextInput label={"Name"} name={"name"} />
            <TextInput label={"Email"} type={"email"} name={"email"} />
            <div className={"flex"}>
              <Button type={"submit"}>Sign Up</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
