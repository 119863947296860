import { useSelector } from "react-redux";
import { selectAccount } from "../store/account-slice";
import { useEffect, useState } from "react";
import { MailingList } from "../models/models";
import { useLazyGetMailingListsByAccountIdQuery } from "@store/adsSlice";

export default function useMailingList() {
  const account = useSelector(selectAccount);
  const [getMailingListsByAccountId] = useLazyGetMailingListsByAccountIdQuery();
  const [mailing_list, setMailingList] = useState<MailingList>();
  const [render, setRender] = useState(0);

  const refetch = () => {
    setRender((r) => r + 1);
  };

  useEffect(() => {
    if (!account) return;
    const load = async () => {
      const result = await getMailingListsByAccountId(account.id).unwrap();
      setMailingList(result[0]);
    };
    load();
  }, [account, render]);

  return { mailing_list, refetch };
}
