import { EventListFilterTypes, Product } from "@models/models";
import Select, { MultiValue, SingleValue } from "react-select";
import {
  getVariantDisplayTitle,
  getVariantShowtimeOnly,
} from "@components/variant-info";
import * as React from "react";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useLazyGetProductQuery } from "@store/apiSlice";
import { useAccount } from "@hooks/account";
import { useLazyGetProductsToManageQuery } from "@store/adsSlice";

export type SelectedVariantInfo = { id: string; title: string };

export default function VariantSelector({
  hideProductTitle,
  onChange,
  defaultValue,
  isMulti,
  productId,
  showVariantTitle,
  product,
}: {
  product?: Product;
  hideProductTitle?: boolean;
  productId?: string;
  defaultValue?: any;
  isMulti: boolean;
  showVariantTitle?: boolean;
  onChange: (
    selected_variant: SelectedVariantInfo | SelectedVariantInfo[],
  ) => void;
}) {
  const { account } = useAccount();
  const [getProduct] = useLazyGetProductQuery();
  const [getProducts] = useLazyGetProductsToManageQuery();
  const [products, setProducts] = useState<Product[]>();

  useEffect(() => {
    if (product) {
      setProducts([product]);
    } else if (productId) {
      getProduct({ product_id: productId }).then((r) =>
        setProducts([r.data as Product]),
      );
    } else if (account) {
      getProducts({
        account_id: account.id,
        filter_type: EventListFilterTypes.SHOW_ALL,
        paginate: false,
      }).then((r) => setProducts(r.data?.data));
    }
  }, [account]);

  if (!products) return <div>Loading...</div>;
  return (
    <div>
      <Select
        isMulti={isMulti}
        defaultValue={defaultValue}
        getOptionLabel={(v: SelectedVariantInfo) => v.title}
        getOptionValue={(v: SelectedVariantInfo) => v.id}
        onChange={(
          variants:
            | MultiValue<SelectedVariantInfo>
            | SingleValue<SelectedVariantInfo>,
        ) => {
          let result;
          if (_.isArray(variants)) {
            onChange(variants.map((v) => ({ id: v.id, title: v.title })));
          } else if (variants) {
            const casted = variants as SingleValue<{
              id: string;
              title: string;
            }>;
            if (casted) {
              onChange({ id: casted.id, title: casted.title });
            }
          }
        }}
        options={products.flatMap((p) =>
          _.sortBy(p.variants, (v) => v.startsAt).map((v) => ({
            id: v.id,
            title: `${hideProductTitle ? "" : `${p.title} - `}${showVariantTitle ? getVariantDisplayTitle(v, false) : getVariantShowtimeOnly(v)}`,
          })),
        )}
      />
    </div>
  );
}
