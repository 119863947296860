import { Product, ProductVariant } from "@models/models";
import { CartCustomerValues } from "@app/[locale]/b/[domain]/store/cart/page";
import { boolean, object, string } from "yup";
import Medusa from "@medusajs/medusa-js";
import { Cart } from "@medusajs/medusa";

export const isVariantInStock = (variant: ProductVariant) => {
  return variant.manage_inventory && variant.inventory_quantity !== 0;
};

export const isProductInStock = (product: Product) => {
  const variantsToCheck = product.activeVariants
    ? product.activeVariants
    : product.variants;
  return variantsToCheck.find((v) => isVariantInStock(v)) !== undefined;
};

export const validateCustomerCartFields = (values: CartCustomerValues) => {
  let customerSchema = object({
    email: string().required("Email is required.").email("Email is required."),
    firstName: string().required("First name is required."),
    lastName: string().required("Last name is required."),
    termsOfService: boolean().oneOf(
      [true],
      "You must agree to the Terms of Service",
    ),
  });
  customerSchema.validateSync(values, { abortEarly: false });
};

export const createCustomer = async (
  values: CartCustomerValues,
  cartId: string,
  medusa: Medusa,
): Promise<string | null> => {
  const response = await medusa.customers.create({
    email: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
    password: Math.random() + "",
  });
  const newCustomer = response.customer;
  if (newCustomer) {
    await medusa.carts.update(cartId, {
      customer_id: newCustomer.id,
    });
    return newCustomer.id;
  } else {
    const updateResult = await medusa.carts.update(cartId, {
      email: values.email,
    });
    return updateResult.cart.customer_id;
  }
};

export const getItemCount = (cart: Partial<Cart>) => {
  if (!cart || !cart.items) return 0;
  return cart.items.reduce((total, currentValue, currentIndex, arr) => {
    return total + currentValue.quantity;
  }, 0);
};
