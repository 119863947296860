import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { Brand } from "@models/models";

export interface SiteState {
  brand?: Brand;
  isLoading: boolean;
}

const initialState: SiteState = {
  brand: undefined,
  isLoading: false,
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setBrand(state, action) {
      state.brand = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export function getBrandThemeSettingValue(
  theme_settings: Record<string, any> | undefined,
  setting_name: string,
) {
  if (theme_settings && theme_settings[setting_name]) {
    return theme_settings[setting_name];
  } else {
    // @ts-ignore
    let key: keyof typeof BRAND_THEME_SETTING_KEYS = setting_name;
    return brand_theme_setting_defaults[key];
  }
}

export const selectBrandThemeSetting =
  (setting_name: string) => (state: AppState) => {
    return getBrandThemeSettingValue(
      state.site.brand?.theme_settings,
      setting_name,
    );
  };

export const selectBrand = (state: AppState): Brand | undefined =>
  state.site.brand;
export const selectIsLoading = (state: AppState): any => state.site.isLoading;

export const { setBrand, setIsLoading } = siteSlice.actions;

export default siteSlice.reducer;

export enum BRAND_THEME_SETTING_KEYS {
  site_background_color = "site_background_color",
  menu_link_color = "menu_link_color",
  product_list_background_color = "product_list_background_color",
  product_detail_background_color = "product_detail_background_color",
  card_background_color = "card_background_color",
  site_text_color = "site_text_color",
}
const brand_theme_setting_defaults = {
  [BRAND_THEME_SETTING_KEYS.site_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.product_list_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.product_detail_background_color]: "#ffffff",
  [BRAND_THEME_SETTING_KEYS.menu_link_color]: "#374151",
  [BRAND_THEME_SETTING_KEYS.card_background_color]: "#EEEEEE",
  [BRAND_THEME_SETTING_KEYS.site_text_color]: "#000000",
};
