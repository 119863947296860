import {
  MetaCity,
  MetaCountry,
  MetaInterest,
  metaPlatforms,
  UTC_DATE_FORMAT,
} from "../models/ad-campaigns";
import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/customParseFormat"));
export interface MetaCreateAdSetState {
  cities: MetaCity[];
  interests: MetaInterest[];
  countries: MetaCountry[];
  radius: number;
  custom_audience_ids: { id: string; name: string }[];
  excluded_custom_audience_ids: { id: string; name: string }[];
  budget_type: string;
  bid_amount: number;
  budget_amount: number;
  pixel_id: string;
  start_time: string | null;
  end_time: string | null;
  platform_ids: string[];
  bid_strategy: string;
}

const initialState: MetaCreateAdSetState = {
  cities: [],
  interests: [],
  countries: [],
  bid_amount: 20,
  budget_amount: 500,
  budget_type: "lifetime_budget",
  custom_audience_ids: [],
  excluded_custom_audience_ids: [],
  end_time: toUtcString(dayjs().add(2, "w").toDate()),
  start_time: toUtcString(dayjs().toDate()),
  pixel_id: "",
  radius: 10,
  platform_ids: metaPlatforms.map((m) => m.id),
  bid_strategy: "LOWEST_COST_WITHOUT_CAP",
};

const metaCreateAdSetSlice = createSlice({
  name: "metaCreateAdSet",
  initialState,
  reducers: {
    setMetaCreateAdSetTargeting(state, action) {
      const values = action.payload;
      state.custom_audience_ids = values.custom_audience_ids;
      state.excluded_custom_audience_ids = values.excluded_custom_audience_ids;
      state.platform_ids = values.platform_ids;
      state.cities = values.cities;
      state.interests = values.interests;
      state.countries = values.countries;
      state.radius = values.radius;
    },
  },
});

export function toUtcString(date: Date | null): string | null {
  if (date == null) return null;
  // @ts-ignore
  const d = dayjs.utc(date);
  return d.isValid() ? d.format(UTC_DATE_FORMAT) : null;
}

export function fromUtcString(date: string | null): Date | null {
  if (date == null) return null;
  // @ts-ignore
  const d = dayjs.utc(date, UTC_DATE_FORMAT);
  return d.isValid() ? d.toDate() : null;
}

export const selectMetaCreateAdSetState = (
  state: AppState,
): MetaCreateAdSetState | undefined => state.metaCreateAdSet;

export default metaCreateAdSetSlice.reducer;

export const { setMetaCreateAdSetTargeting } = metaCreateAdSetSlice.actions;
