import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";
import React from "react";

export interface MessagesState {
  errors: string[];
  success: (string | React.ReactNode)[];
}

const initialState: MessagesState = {
  errors: [],
  success: [],
};

interface ErrorResponse {
  data: { error: string };
}
export function isErrorResponse(obj: any): obj is ErrorResponse {
  return obj !== undefined && (obj as ErrorResponse).data?.error !== undefined;
}

// @ts-ignore TODO
const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addError(state, action) {
      state.errors = [];
      if (isErrorResponse(action.payload)) {
        state.errors.push(action.payload.data.error);
      } else if (Array.isArray(action.payload)) {
        action.payload.map((m: string) => state.errors.push(m));
      } else if (typeof action.payload === "string") {
        state.errors.push(action.payload);
      }
    },
    addSuccess(state, action) {
      state.success = [];
      if (Array.isArray(action.payload)) {
        action.payload.map((m: string) => state.success.push(m));
      } else {
        state.success.push(action.payload);
      }
    },
    clearMessages(state) {
      state.errors = [];
      state.success = [];
    },
  },
});

export const selectErrors = (state: AppState): string[] =>
  state.messages.errors;
export const selectSuccess = (state: AppState): (string | React.ReactNode)[] =>
  state.messages.success;

export const { addError, addSuccess, clearMessages } = messagesSlice.actions;

export default messagesSlice.reducer;
