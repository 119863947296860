import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { Account } from "../models/models";
import axios from "axios";

export enum AccountStatus {
  NOT_INITIALIZED,
  ASSIGNED,
  INITIALIZED_NOT_ASSIGNED,
}

export interface AccountState {
  account: Account | undefined;
  accounts: Account[];
  accountStatus: AccountStatus;
}

const initialState: AccountState = {
  account: undefined,
  accountStatus: AccountStatus.NOT_INITIALIZED,
  accounts: [],
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount(state, action) {
      state.account = action.payload;
      if (state.account && state.account.id) {
        state.accountStatus = AccountStatus.ASSIGNED;
        localStorage.setItem("accountId", state.account.id);
      }
    },
    setInitializedNotAssigned(state) {
      state.accountStatus = AccountStatus.INITIALIZED_NOT_ASSIGNED;
    },
  },
});

export const initializeAccounts = createAsyncThunk(
  "jump/accounts/initialize",
  async (arg, thunkAPI) => {
    const adminUser = (thunkAPI.getState() as any).user.admin;
    if (!adminUser) {
      return;
    }
    const accountId = localStorage.getItem("accountId");
    if (accountId) {
      const account: Account = (
        await axios.get(
          `${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/admin/accounts/${accountId}`,
          {
            withCredentials: true,
            headers: { "x-medusa-access-token": `${adminUser.api_token}` },
          },
        )
      ).data;
      thunkAPI.dispatch(setAccount(account));
    } else {
      const accounts: Account[] = (
        await axios.get(
          `${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/admin/accounts`,
          {
            withCredentials: true,
            headers: { "x-medusa-access-token": `${adminUser.api_token}` },
          },
        )
      ).data;
      if (accounts.length === 1) {
        thunkAPI.dispatch(setAccount(accounts[0]));
      } else {
        thunkAPI.dispatch(setInitializedNotAssigned());
      }
    }
  },
);

export const selectAccount = (state: AppState): Account | undefined =>
  state.account.account;
export const selectAccountStatus = (state: AppState): AccountStatus =>
  state.account.accountStatus;

export default accountSlice.reducer;

export const { setAccount, setInitializedNotAssigned } = accountSlice.actions;
