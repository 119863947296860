import { useEffect, useState } from "react";
import { Prize } from "@models/models";
import { getSpinResultIndex } from "@utils/spin-wheel-prize-picker";
import spinWheelColors, { SpinWheelColors } from "@utils/spin-wheel-colors";
type SpinWheelOption = {
  segmentText: string;
  segColor: string;
};
const WHEEL_SLICES = 6;
const allOptions = (): SpinWheelOption[] => [
  { segmentText: "", segColor: "#abcdee" },
  { segmentText: "", segColor: "#abcdef" },
  { segmentText: "", segColor: "#ace5ee" },
  { segmentText: "", segColor: "#b0e0e6" },
  { segmentText: "", segColor: "#a1caf1" },
  { segmentText: "", segColor: "#a4dded" },
];
import { SpinWheel as SpinWheelGame } from "spin-wheel-game";
import { isMobile } from "react-device-detect";

function getRandomUniqueIntegers(
  total_count: number,
  pick_count: number,
): number[] {
  if (pick_count > total_count) {
    throw new Error("pick_count cannot be greater than total_count");
  }

  const uniqueNumbers = new Set<number>();

  while (uniqueNumbers.size < pick_count) {
    const randomNum = Math.floor(Math.random() * (total_count + 1));
    uniqueNumbers.add(randomNum);
  }

  return Array.from(uniqueNumbers);
}

function generateData(
  prizes: Prize[],
  base_color: SpinWheelColors | null,
): SpinWheelOption[] {
  let lo;
  if (!base_color) {
    lo = allOptions();
  } else {
    lo = spinWheelColors[base_color].map((color) => ({
      segmentText: "",
      segColor: color,
    }));
  }
  let data: SpinWheelOption[] = [];
  for (let i = 0; i < WHEEL_SLICES; i++) {
    // @ts-ignore
    data.push({
      ...lo.shift(),
    });
  }
  const winning_indexes = getRandomUniqueIntegers(
    WHEEL_SLICES - 1,
    prizes.length,
  );
  let prize_label_index = 0;
  for (let i = 0; i < winning_indexes.length; i++) {
    data[winning_indexes[i]].segmentText = prizes[prize_label_index].name;
    prize_label_index++;
  }

  return data;
}

export default function SpinWheel({
  winning_prize_id,
  onGameComplete,
  config,
  prizes,
}: {
  winning_prize_id?: number;
  config: Record<string, any>;
  onGameComplete: () => void;
  prizes: Prize[];
}) {
  const [data, setData] = useState<SpinWheelOption[]>([]);
  const [spinResultIndex, setSpinResultIndex] = useState<number>();

  useEffect(() => {
    const gen_data = generateData(
      prizes,
      config.fields?.base_color?.value ? config.fields.base_color.value : null,
    );

    setSpinResultIndex(
      getSpinResultIndex(structuredClone(gen_data), winning_prize_id, prizes),
    );
    const losing_labels = config.fields?.losing_labels?.value
      ? config.fields.losing_labels.value
      : null;
    if (losing_labels !== null) {
      const losing_labels_options = losing_labels.split(/\n/);
      let losing_labels_index = 0;
      for (let i = 0; i < gen_data.length; i++) {
        if (
          gen_data[i].segmentText === "" &&
          losing_labels_options.length - 1 >= losing_labels_index
        ) {
          gen_data[i].segmentText = losing_labels_options[losing_labels_index];
          losing_labels_index++;
        }
      }
    }
    for (let i = 0; i < gen_data.length; i++) {
      if (gen_data[i].segmentText == "") {
        gen_data[i].segmentText = "Sorry!";
      }
    }

    setData(gen_data);
  }, []);
  if (!data || data.length === 0 || spinResultIndex === undefined)
    return <div>Loading...</div>;
  return (
    <div className={"flex flex-col gap-8"}>
      <SpinWheelGame
        size={isMobile ? 125 : undefined}
        isSpinSound={false}
        segments={data}
        onFinished={() => {
          onGameComplete();
        }}
      />
    </div>
  );
}
