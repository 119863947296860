import { Prize } from "@models/models";

export function getSpinResultIndex(
  options: { segmentText: string }[],
  winning_prize_id: number | undefined,
  prizes: Prize[],
) {
  if (winning_prize_id) {
    const winning_prize = prizes.find((p) => p.id === winning_prize_id);
    if (winning_prize) {
      const winning_option = options.reduce((acc, item, idx) => {
        if (item.segmentText === winning_prize.name) {
          acc.push(idx);
        }
        return acc;
      }, [] as number[]);
      if (winning_option.length > 0) {
        return winning_option[0];
      }
    }
  } else {
    const losing_options = options.reduce((acc, item, idx) => {
      if (item.segmentText === "") {
        acc.push(idx);
      }
      return acc;
    }, [] as number[]);
    return losing_options[0];
  }
}
