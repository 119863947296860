import { useSelector } from "react-redux";
import { selectAccount } from "../store/account-slice";
import { useEffect, useState } from "react";
import { Account } from "../models/models";
import { useLazyGetAccountQuery } from "../store/apiSlice";

export const useAccount = () => {
  const storedAccount = useSelector(selectAccount);
  const [account, setAccount] = useState<Account>();
  const [getAccount] = useLazyGetAccountQuery();
  const [render, setRender] = useState(0);

  useEffect(() => {
    if (!storedAccount) return;
    getAccount(storedAccount.id).then((r) => setAccount(r.data));
  }, [storedAccount, render]);

  const refetch = () => setRender((r) => r + 1);

  return { account, refetch };
};
