import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cartReducer from "./cart-slice";
import messagesReducer from "./messages-slice";
import regionReducer from "./region-slice";
import siteReducer from "./site-slice";
import { createWrapper } from "next-redux-wrapper";
import { useDispatch } from "react-redux";
import { apiSlice } from "./apiSlice";
import { reportingSlice } from "./reportingSlice";
import userReducer from "./user-slice";
import accountReducer from "./account-slice";
import posReducer from "./pos-slice";
import { adsSlice } from "./adsSlice";
import metaCreateAdSetReducer from "./meta-create-ad-set-slice";

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [reportingSlice.reducerPath]: reportingSlice.reducer,
    [adsSlice.reducerPath]: adsSlice.reducer,
    metaCreateAdSet: metaCreateAdSetReducer,
    messages: messagesReducer,
    user: userReducer,
    region: regionReducer,
    site: siteReducer,
    account: accountReducer,
    pos: posReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(reportingSlice.middleware)
      .concat(adsSlice.middleware),
  devTools: true,
});
const makeStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const wrapper = createWrapper<AppStore>(makeStore);
