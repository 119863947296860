import React, { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import {
  useLazyGetImagesQuery,
  useUploadSingleImageMutation,
} from "../store/apiSlice";
import { CustomImage } from "../models/models";
import Image from "next/image";

type ImageUploaderProps = {
  hideGallery?: boolean;
  selectOnUpload?: boolean;
  onClose: () => void;
  onSelect?: any;
  uploadButtonText?: string;
  hideCancelButton?: boolean;
};
export default function ImageUploader({
  hideCancelButton,
  uploadButtonText,
  selectOnUpload,
  hideGallery,
  onClose,
  onSelect,
}: ImageUploaderProps) {
  const [images, setImages] = React.useState<CustomImage[]>();
  const [uploadSingleImage] = useUploadSingleImageMutation();
  const [getImages] = useLazyGetImagesQuery();
  const [render, setRender] = useState(0);

  useEffect(() => {
    const load = async () => {
      setImages(await getImages().unwrap());
    };
    load();
  }, [render, getImages]);

  const selectImage = (index: number) => {
    if (onSelect && images) {
      onSelect({ url: images[index].url });
    }
    onClose();
  };

  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ) => {
    // data for submit
    if (addUpdateIndex && addUpdateIndex.length) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", imageList[addUpdateIndex[0]].file);
      const result = await uploadSingleImage(formData).unwrap();
      if (selectOnUpload) {
        onSelect({ url: result.file.url });
        onClose();
      }
      setRender((r) => r + 1);
    }
    setImages(imageList as never[]);
  };

  if (!images) return <div>Loading...</div>;

  return (
    <div className="App">
      <ImageUploading multiple value={images} onChange={onChange}>
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className={"flex gap-x-2"}>
              <button
                type={"button"}
                className={"btn btn-accent mb-2"}
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                {uploadButtonText ? uploadButtonText : "Click to Upload"}
              </button>
              {!hideCancelButton && (
                <button onClick={() => onClose()} className={"btn"}>
                  Cancel
                </button>
              )}
            </div>
            {!hideGallery && (
              <div className={"flex flex-wrap gap-2"}>
                {images.map((image, index) => (
                  <Image
                    key={index}
                    onClick={() => selectImage(index)}
                    src={image.url}
                    alt="img"
                    width={0}
                    height={0}
                    sizes={`50vw`}
                    style={{
                      maxHeight: "100px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
